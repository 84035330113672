$brk-base-colors: ();
$brk-base-colors: map-merge((
				"brand-primary":      #fdb415,
				"secondary": 					#ffc109,
				"white":              #fff,
				"black":              #000,
				"brk-dark-base":      #272727,
				"brk-base-1": 				#fddb00,
				"brk-base-2": 				#fd8000,
				"brk-base-3": 				#fd8000,
				"brk-base-4": 				#feff8c,
				"brk-base-5": 				#fdb415,
				"brk-base-6":         #fdb415,
				"brk-base-7": 				#fcfdab,
				"brk-base-8": 				#fd8500,
				"brk-secondary-1": 		#dfa100,
				"brk-secondary-2": 		#ba8100,
				"brk-secondary-3": 		#ba7800,
				"brk-secondary-4": 		#c2a855,
				"brk-secondary-5": 		#dfd26c,
				"brk-secondary-6": 		#ffd714,
				"text-base-color-1":  #c4c4c4,
				"text-base-color-2": 	#e0ab12,
				"text-base-color-3":  #f3f3f3,
				"form-color-1":				#e5e5e5
), $brk-base-colors);

@function safe-rgb($color) {
	@return unquote("#{red($color)}, #{green($color)}, #{blue($color)}");
}

/* ----------- Palette variable ----------- */
:root {
	@each $color, $value in $brk-base-colors {
		--#{$color}: #{$value};
	}

	// RGB
	@each $color, $value in $brk-base-colors {
		--#{$color}-rgb: #{safe-rgb($value)};
	}

	--b-radius: inherit;
}
/* ----------- Palette variable ----------- */